import React from "react";
import { MDBFooter, MDBContainer, MDBIcon, MDBBtn } from "mdb-react-ui-kit";

export default function Footer() {
  return (
    <MDBFooter
      className="text-center text-white"
      style={{ backgroundColor: "#000000" }}
    >
      <MDBContainer className="p-4 pb-0">
        <section className="mb-4">
          <MDBBtn
            outline
            color="light"
            floating
            className="m-1"
            href="https://www.facebook.com/goldentouch902"
            role="button"
          >
            <MDBIcon fab icon="facebook-f" />
            facebook
          </MDBBtn>

          <MDBBtn
            outline
            color="light"
            floating
            className="m-1"
            href="mailto:goldentouch902@gmail.com"
            role="button"
          >
            <MDBIcon fab icon="google" />
            gmail
          </MDBBtn>

          <MDBBtn
            outline
            color="light"
            floating
            className="m-1"
            href="#!"
            role="button"
          >
            <MDBIcon fab icon="youtube" />
            youtube
          </MDBBtn>

          <MDBBtn
            outline
            color="light"
            floating
            className="m-1"
            href="https://api.whatsapp.com/send?phone=%2B19027186803&fbclid=IwAR3sKpm4xacNBrjWK3y30-BUWWruniuwTpsIn3xG_h_byc7gdmE5Y51HFVA"
            role="button"
          >
            <MDBIcon fab icon="whatsapp" />
            whatsapp
          </MDBBtn>
        </section>
      </MDBContainer>

      <div
        className="text-center p-3"
        style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
      >
        © 2023 Copyright: Golden Touch Landscaping
      </div>
    </MDBFooter>
  );
}
