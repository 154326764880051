import React, { useState, useEffect } from "react";
import NavBar from "./Component/NavBar";
import HomePage from "./pages/HomePage";
import Services from "./pages/Services";
import Testimonials from "./pages/Testimonials";
import Team from "./pages/Team";
import Gallery from "./pages/Gallery";
import About from "./pages/About";
import Contact from "./pages/Contact";
import data from "./data/data.json";
import "./App.css";

const App = () => {
  const [pageData, setPageData] = useState({});
  useEffect(() => {
    setPageData(data);
  }, []);
  return (
    <div className="content-container">
      <NavBar />
      <HomePage id="homepage" key="homepage" data={data.HomePage} />
      <About id="about" key="about" data={data.About} />
      <Services id="services" key="services" data={data.Services} />
      <Testimonials
        id="testimonials"
        key="testimonials"
        data={data.Testimonials}
      />
      <Gallery id="gallery" key="gallery" data={data.Gallery} />
      <Team id="team" key="team" data={data.Team} />
      <Contact id="contact" key="contact" data={data.Contact} />
    </div>
  );
};

export default App;
