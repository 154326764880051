import React from "react";
import Iframe from "react-iframe";

const Map = () => (
    <Iframe
      src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2836.6071526380797!2d-63.532306184468446!3d44.686801779099554!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4b5a24122fd5439b%3A0x36b44d582f8d84c5!2s33%20Caledonia%20Rd%2C%20Dartmouth%2C%20NS%20B2X%201K7!5e0!3m2!1sen!2sca!4v1670123303686!5m2!1sen!2sca'
      width='100%'
        height='100%'
      style={{ border: "0" }}
      allowfullscreen=''
      loading='lazy'
      referrerPolicy='no-referrer-when-downgrade'
    />
);

export default Map;
