import React from "react";

function Services({ data, id }) {
  return (
    <div id={id} className="pt-5">
      <div className="m-5">
        <div className="text-center">
          <h2 className="fw-bold mb-4">OUR SERVICES</h2>
          <p>
            "We offer a wide range of services to meet every type of landscaping
            need."
          </p>
        </div>
        <div className="row justify-content-center flex-fill">
          {data
            ? data.map((data, index) => (
                <div
                  key={`${data.name}-${index}`}
                  className=" rounded-6 bg-opacity-50 col-md-2 m-1 flex-fill"
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    padding: "1rem",
                  }}
                >
                  <i
                    className={data.icon}
                    style={{ fontSize: "30px", padding: "1rem" }}
                  ></i>
                  <div>
                    <h3>{data.name}</h3>
                    <hr />
                    <p>{data.text}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
}

export default Services;
