import React from "react";
import { Link } from "react-scroll";
import homebackground from "../assets/images/heroimage.webp";
import { MDBTypography } from "mdb-react-ui-kit";
import "./HomePage.css";

function HomePage({ data, id }) {
  return (
    <div
      id={id}
      className="bg-image"
      style={{
        backgroundImage: `url(${homebackground})`,
        height: "100vh",
      }}
    >
      <div className="mask" style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}>
        <div className="d-flex justify-content-center align-items-center h-100">
          <div>
            <div>
              <h1 id="h1hp" className="text-wrap text-center fw-bold">
                {data.title}
              </h1>
            </div>
            <div>
              <p className="lead text-wrap text-center mb-3 paragraph">
                {data.paragraph}
              </p>
            </div>
            <div className="text-center">
              <MDBTypography className="mb-3 text">
                <strong>
                  Our Core Values:
                  <span className="corevalues"></span>
                </strong>
              </MDBTypography>
            </div>
            <div className="text-center">
              <Link
                id="contactbtn"
                className="btn btn-outline-light btn-lg"
                to="contact"
                role="button"
              >
                Contact us now!
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
