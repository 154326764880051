import React from "react";

function Team({ data, id }) {
  return (
    <div id={id} className="pt-5">
      <div className="m-5">
        <div className="text-center">
          <h2 style={{ color: "#ffd400" }} className="fw-bold mb-4">
            THE GOLDEN TEAM
          </h2>
          <p>
            "Our team works round the clock to give your landscape a touch of
            gold.
            <br />
            Transform your outdoor space with our expert landscapers. With years
            of experience and a deep knowledge of materials, we'll bring your
            vision to life. <br />
            Contact us to schedule a consultation and discover the
            possibilities!"
          </p>
        </div>
        <div className="container px-5 ">
          <div className="row justify-content-center">
            {data
              ? data.map((data, index) => (
                  <div
                    key={`${data.name}-${index}`}
                    className=" rounded-6 bg-opacity-50 col-md-2 m-4 text-center shadow-5-strong"
                    style={{
                      backgroundColor: "#ffd400",
                      color: "white",
                      padding: "1rem",
                    }}
                  >
                    <div>
                      <img
                        src={data.img}
                        alt={data.name}
                        width={200}
                        className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"
                      />
                      <h4 style={{ color: "black" }}>{data.name}</h4>
                      <p style={{ color: "black" }}>{data.job}</p>
                    </div>
                  </div>
                ))
              : "loading"}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Team;
