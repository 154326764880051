import React, { useState } from "react";
import {
  MDBNavbar,
  MDBContainer,
  MDBIcon,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarToggler,
  MDBCollapse,
} from "mdb-react-ui-kit";
import { Link, animateScroll as scroll } from "react-scroll";

const NavBar = () => {
  const [showNav, setShowNav] = useState(false);

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  const handleShowNav = () => {
    setShowNav(!showNav);
  };

  const closeNav = () => {
    setShowNav(false);
  };

  return (
    <MDBNavbar fixed="top" expand="lg" light bgColor="light">
      <MDBContainer className="mx-4" fluid>
        <MDBNavbarBrand>
          <img
            src={require("../assets/images/logo.png")}
            alt="golden_touch_landscaping_logo"
            width="120"
            className="nav-logo"
            loading="lazy"
            onClick={scrollToTop}
          />
        </MDBNavbarBrand>
        <MDBNavbarToggler
          type="button"
          data-target="#navbarCentered"
          aria-controls="navbarCentered"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={handleShowNav}
        >
          <MDBIcon icon="bars" fas />
        </MDBNavbarToggler>

        <MDBCollapse
          className="justify-content-center"
          navbar
          show={showNav}
          center="true"
          id="navbarCentered"
        >
          <MDBNavbarNav fullWidth={false}>
            {[
              { to: "homepage", text: "HOME" },
              { to: "about", text: "ABOUT" },
              { to: "services", text: "SERVICES" },
              { to: "testimonials", text: "TESTIMONIALS" },
              { to: "gallery", text: "GALLERY" },
              { to: "team", text: "TEAM" },
              { to: "contact", text: "CONTACT" },
            ].map((link) => (
              <MDBNavbarItem key={link.to}>
                <MDBNavbarLink>
                  <Link
                    spy={true}
                    smooth={true}
                    to={link.to}
                    onClick={closeNav}
                    style={{  fontWeight: "600" }}
                    onMouseEnter={(e) =>
                      (e.target.style.textDecoration = "underline")
                    }
                    onMouseLeave={(e) =>
                      (e.target.style.textDecoration = "none")
                    }
                  >
                    {link.text}
                  </Link>
                </MDBNavbarLink>
              </MDBNavbarItem>
            ))}
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBContainer>
    </MDBNavbar>
  );
};

export default NavBar;
