import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdb-react-ui-kit";
import Map from "../Component/Map";
import ContactForm from "../Component/ContactForm";
import Footer from "../Component/Footer";

const Contact = ({ data, id }) => {
  return (
    <div className="p-5" style={{ backgroundColor: "black" }} id={id}>
      <MDBContainer>
        <MDBRow className="pt-5">
          <MDBCol lg={true} xs={12} className="text-center">
            <h2 style={{ color: "#ffd400" }} className="fw-bold mb-2">
              Let's give your property a golden touch!
            </h2>
            <h3 style={{ color: "#ffd400" }} className="fw-bold mb-2">
              Call us now!
            </h3>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol lg={4} xs={12} className="mt-3">
            <Map alt="Location map of golden touch landscaping" />
          </MDBCol>
          <MDBCol lg={4} xs={12} className="mt-3 text-center">
            <ContactForm title={"Get in Touch"} />
          </MDBCol>
          <MDBCol lg={4} xs={12} className="mt-3">
            <div id="address">
              <span className="text-white">
                <MDBIcon fas icon="map-marker" className="pe-2" />
                Contact Address:
              </span>
              <address style={{ color: "#ffd400" }}>{data.address}</address>
              <hr className="separator" />
              <span className="text-white">
                <MDBIcon fas icon="mobile-alt" className="pe-2" />
                Mobile:
              </span>
              <ul className="text-white px-0">
                <li>
                  <p>
                    <MDBIcon
                      icon="check-circle"
                      className="me-2 text-success"
                    />
                    Office Line:
                    <a
                      href={`tel:${data.phone1}`}
                      style={{ color: "#ffd400" }}
                      onMouseEnter={(e) =>
                        (e.target.style.textDecoration = "underline")
                      }
                      onMouseLeave={(e) =>
                        (e.target.style.textDecoration = "none")
                      }
                    >
                      {" "}
                      {data.phone1}
                    </a>
                  </p>
                </li>
                <li>
                  <p>
                    <MDBIcon
                      icon="check-circle"
                      className="me-2 text-success"
                    />
                    Mobile Number:
                    <a
                      href={`tel:${data.phone2}`}
                      style={{ color: "#ffd400" }}
                      onMouseEnter={(e) =>
                        (e.target.style.textDecoration = "underline")
                      }
                      onMouseLeave={(e) =>
                        (e.target.style.textDecoration = "none")
                      }
                    >
                      {" "}
                      {data.phone2}
                    </a>
                  </p>
                </li>
              </ul>
              <hr className="separator " />
              <span className="text-white">
                <MDBIcon far icon="envelope" className="pe-2" />
                Email Address:
              </span>
              <p>
                <a
                  style={{
                    color: "#ffd400",
                  }}
                  href={`mailto:${data.email}`}
                  onMouseEnter={(e) =>
                    (e.target.style.textDecoration = "underline")
                  }
                  onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
                >
                  {data.email}
                </a>
              </p>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <div>
        <Footer key="footer" />
      </div>
    </div>
  );
};

export default Contact;
