import React from "react";
import { MDBTypography, MDBIcon } from "mdb-react-ui-kit";

const About = ({ data, id }) => {
  return (
    <div
      id={id}
      className="pt-5"
      style={{
        backgroundColor: "#ffd400",
      }}
    >
      <div className="overflow-hidden py-5 px-3">
        <div className="row gx-0 d-flex flex-wrap">
          <div className="mb-4 mb-md-0 px-3 col-md-5 my-4">
            <div className="d-flex py-md-5">
              <img
                loading="lazy"
                className="w-100 img-fluid shadow-5-strong"
                src={require("../assets/images/about-us.webp")}
                alt="A nicely landscaped yard"
              />
            </div>
          </div>
          <div className="p-3 col-md-7 rounded-6 bg-light bg-opacity-50 text-dark shadow-5-strong">
            <h2 className="fw-bold mb-4">ABOUT US</h2>

            <p>{data.paragraph}</p>
            <h3 className="fw-bold mb-4">
              Why You Should do Business with Us.
            </h3>
            <div className="d-flex flex-wrap">
              <div className="px-3">
                <MDBTypography listUnStyled className="mb-0">
                  {data
                    ? data.Why.map((data, index) => (
                        <li key={`${data}-${index}`}>
                          <MDBIcon
                            icon="check-circle"
                            className="me-2 text-success"
                          />
                          {data}
                        </li>
                      ))
                    : "loading"}
                </MDBTypography>
              </div>
              <div className="px-3">
                <MDBTypography listUnStyled className="mb-0">
                  {data
                    ? data.Why2.map((data, index) => (
                        <li key={`${data}-${index}`}>
                          {" "}
                          <MDBIcon
                            icon="check-circle"
                            className="me-2 text-success"
                          />
                          {data}
                        </li>
                      ))
                    : "loading"}
                </MDBTypography>
              </div>
            </div>
            <figure className="text-center mt-5">
              <MDBTypography blockquote>
                <MDBTypography tag="em">
                  {data ? data.moto : "loading"}
                </MDBTypography>
              </MDBTypography>
              <figcaption className="blockquote-footer mb-0">
                Chris Panton <cite title="Source Title">Owner</cite>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
