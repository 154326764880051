import React from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCardTitle,
  MDBCardText,
} from "mdb-react-ui-kit";
import Rating from "react-rating";

export default function Testimonials({ data, id }) {
  return (
    <div
      id={id}
      style={{
        backgroundColor: "#f6f6f6",
      }}
      className="py-5"
    >
      <div className="m-5">
        <div className="text-center">
          <h2 className="fw-bold mb-4">WHAT OUR CLIENTS SAY</h2>
          <p>
            "We provide stellar services to our clients and they testify of it."
          </p>
        </div>
        <div className=" row justify-content-center flex-fill">
          {data
            ? data.map((data, index) => (
                <MDBCard
                  shadow="0"
                  border="warning"
                  className="m-2 shadow-5-strong"
                  background="light"
                  style={{ maxWidth: "400px" }}
                  key={index}
                >
                  <MDBCardHeader
                    className="text-center"
                    border="dark text-dark"
                  >
                    <MDBCardTitle>{data.title}</MDBCardTitle>
                    <Rating
                      initialRating={data.rating}
                      fullSymbol="fa fa-star fa-1x medium text-warning"
                      readonly
                    />
                  </MDBCardHeader>
                  <MDBCardBody className="text-dark">
                    <MDBCardText>{data.text}</MDBCardText>
                    <MDBCardTitle>{data.name}</MDBCardTitle>
                  </MDBCardBody>
                </MDBCard>
              ))
            : "loading..."}
        </div>
      </div>
    </div>
  );
}
